<template>
  <div class="submit-form" style="max-width: 100%">
    <div>
      <b-row style="margin-bottom: 5px">
        <b-col sm="2">
          <label class="col-3" for="name">Name:</label>
        </b-col>
        <b-col sm="10">
          <input
              type="text"
              class="form-control"
              id="name"
              required
              v-model="setTopBoxType.name"
              name="name"
          />
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 5px">
        <b-col sm="2">
          <label for="description">Description:</label>
        </b-col>
        <b-col sm="10">
          <b-form-textarea
              id="description"
              v-model="setTopBoxType.description"
              placeholder="Enter description..."
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <button @click="saveSetTopBoxType" class="btn btn-success">Submit</button>
    </div>
  </div>
</template>

<script>
import SetTopBoxTypeDataService from "../services/SetTopBoxTypeDataService";
import SetTopBoxType from "../services/SetTopBoxTypeDataService";

export default {
  name: "Add-Set-Top-Box-Type",
  data() {
    return {
      setTopBoxType: {
        id: this.$route.params.id,
        name: "",
        description: "",
      },
      submitted: false
    };
  },
  methods: {
    saveSetTopBoxType() {
      var data = {
        name: this.setTopBoxType.name,
        description: this.setTopBoxType.description,
      };
      console.log(this.setTopBoxType.name);
      console.log(data);
      SetTopBoxTypeDataService.update(this.setTopBoxType.id, this.setTopBoxType)
          .then(response => {
            this.setTopBoxType.id = response.data.id;
            console.log(response.data);
            this.submitted = true;
            this.$router.push({ name: 'Box Types' });
          })
          .catch(e => {
            console.log(e);
          });
    },

    retrieveSetTopBoxType(id) {
      SetTopBoxType.get(id)
          .then(response => {
            this.setTopBoxType = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.retrieveSetTopBoxType(this.setTopBoxType.id);
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
